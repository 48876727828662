<template>
    <div>
        <a v-for="(transfer,key) in row.item.bank_transfers"
           :key="key"
           class="mr-2"
           @click="$root.$children[0].openModal('json-modal', {data: transfer.json})"
           href="#">{{ transfer.id }}</a>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
    methods: {
        openBankTransfer(transfer) {

        },
    }
}
</script>